const moment = require('moment')

export const date = function (value, format = 'DD/MM/YYYY') {
  if (!value) return ''

  const timestampRegex = /^[0-9]{10}$/
  if (timestampRegex.test(value)) {
    value *= 1000
  }

  return moment(value).format(format)
}

export const datetime = function (value, format = "DD/MM/YYYY HH:mm") {
  if (!value) return ''

  return moment(value).format(format)
}

export const dateFromNow = function (value) {
  if (!value) return ''

  return moment(value).fromNow()
}

export default { date, datetime, dateFromNow }
