// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import PACKAGE_JSON from '../package.json'

// COMPONENTS
import Vue from 'vue'
import App from './App'
import AlertIcon from './components/AlertIcon'
import EmptyState from './components/EmptyState'
import Summary from './components/Summary'
import { BootstrapVue } from 'bootstrap-vue'
import ToggleButton from 'vue-js-toggle-button'
import DatePicker from 'vue2-datepicker'
import VueSuggest from 'vue-suggest' // TODO à supprimer un jour
import LightTimeline from 'vue-light-timeline' // TODO trouver un meilleur composant et pouvoir l'importer juste dans le composant utilisé et non dans le global
import People from './components/People.vue'
import VeeValidate from 'vee-validate'
import validationMessages from 'vee-validate/dist/locale/fr'
import AlertDunningVersion from "@/components/Alert/AlertDunningVersion"
import PositionAvatar from "@/components/Position/PositionAvatar"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FECIcon from "@/components/FECIcon"
// BootstrapRemplacement
import Avatar from "@/components/BootstrapRemplacement/Avatar"
import Overlay from "@/components/BootstrapRemplacement/Overlay"
import Table from "@/components/BootstrapRemplacement/Table"

// LIBS, HELPERS, MIXINS
import api from '/src/utils/api_factory'
import router from './router'
import store from './store'
import toast from './utils/toast.service'
import confirmBox from './utils/mixins/confirm.mixin'
import confirmLeavePage from './utils/mixins/confirm_leave_page.mixin'
import VueI18n from 'vue-i18n'
import {redirectError} from './utils/helpers.service'
import { library } from '@fortawesome/fontawesome-svg-core'
import moment from 'moment'
import {RIGHTS} from "@/utils/rights.service"

// CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.min.css' // TODO Remove with Vue2
import 'bootstrap-icons/font/bootstrap-icons.css'
import './assets/css/bootstrap-theming.scss'
import './assets/css/global.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
// Filters
import { price, priceHT, priceTTC } from './utils/filters/formatPrice.filter'
import { date, datetime, dateFromNow } from './utils/filters/dates.filter'
import { fullName, initials, capitalize, uppercase, lowercase } from './utils/filters/names.filter'
import {mapActions, mapGetters} from "vuex"

// Font Awesome
import { faStethoscope, faPersonWalkingLuggage, faUserClock, faSackXmark, faCircleExclamation, faStamp, faCircleHalfStroke, faCircle, faMugHot, faPizzaSlice, faCalendarWeek, faHouseLaptop, faClock, faUserGraduate, faCalendarDays, faCalendarDay, faListUl, faEllipsis, faSquareCheck, faSquareXmark, faEyeSlash, faFileExport, faClone } from '@fortawesome/free-solid-svg-icons'
library.add(faStethoscope, faPersonWalkingLuggage, faUserClock, faSackXmark, faCircleExclamation, faStamp, faCircleHalfStroke, faCircle, faMugHot, faPizzaSlice, faCalendarWeek, faHouseLaptop, faClock, faUserGraduate, faCalendarDays, faCalendarDay, faListUl, faEllipsis, faSquareCheck, faSquareXmark, faEyeSlash, faFileExport, faClone)

const locale = 'fr'// Force FR navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0] || 'fr'
moment.locale(locale)

store.dispatch('$a_setVersion', PACKAGE_JSON.version)

Vue.use(BootstrapVue) // TODO Remove with Vue2
Vue.use(DatePicker)
Vue.use(VueI18n)       // Internationalization
Vue.use(ToggleButton) // Switch
Vue.use(LightTimeline)

// Global Components
Vue.component('Avatar', Avatar)
Vue.component('Overlay', Overlay)
Vue.component('VTable', Table)
Vue.component('AlertIcon', AlertIcon)
Vue.component('AlertRhAvailable', () => import('@/components/Alert/AlertRhAvailable'))
Vue.component('empty-state', EmptyState)
Vue.component('summary-text', Summary)
Vue.component('vue-suggest', VueSuggest)
Vue.component('people', People)
Vue.component('PositionAvatar', PositionAvatar)
Vue.component('AlertDunningVersion', AlertDunningVersion)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('FECIcon', FECIcon)

// TODO set la config avec un appel API (certaines config sont valables aussi côté API, pour mutualiser les config et éviter les bugs il serait préférable de récupérer via un appel API plutôt que de copier/coller les config des deux apps
const CONFIG = {
  LIMIT_SEARCH_TEXT_INVOICE: 70000,
  LIMIT_SEARCH_TEXT_QUOTE: 70000
}

Vue.mixin({
  data () {
    return {
      RIGHTS,
      CONFIG
    }
  },
  computed: {
    ...mapGetters(['currentRights', 'companyReadOnly', 'currentUser', 'currentCompany', 'currentPosition', 'currentSubscription'])
  },
  methods: {
    ...mapActions(['$a_addExportPackage']),
    ...confirmBox,
    ...confirmLeavePage,
    ...toast,
    ...{
      copyClipboard (value, text) {
        try {
          navigator.clipboard.writeText(value)
          this.toastInfo(text, { title: 'Copié' })
        } catch (e) {
          this.toastError({ message: "Le texte n'a pas été copié." })
        }
      },
      validateState (ref) {
        if (
          this.veeFields[ref] &&
          (this.veeFields[ref].dirty || this.veeFields[ref].validated)
        ) {
          return !this.veeErrors.has(ref)
        }
        return null
      },
      onShowPosition (position) { this.$router.push({ name: 'Position', params: { company_url: this.currentCompany.url, id: position._id } }) },
      redirectError,
      price,
      priceHT,
      priceTTC,
      date,
      datetime,
      dateFromNow,
      fullName,
      initials,
      capitalize,
      uppercase,
      lowercase
    }
  }
})

window.addEventListener('error', e => {
  // Si morceau de l'appli manquant, faire f5
  if (/Loading chunk [\d]+ failed/.test(e.message)) {
    window.location.reload()
  }
})

api.autoSigned.service.getLocale({ id: 'fr' }).then(resp => {
  let messages = {}
  if (resp.data) {
    messages[locale] = JSON.parse(JSON.stringify(resp.data))
  }
  // Load Locales
  const i18n = new VueI18n({ locale, messages })

  Vue.use(VeeValidate, {
    // This is the default
    inject: true,
    // Important to name this something other than 'fields'
    fieldsBagName: 'veeFields',
    // This is not required but avoids possible naming conflicts
    errorBagName: 'veeErrors',
    i18nRootKey: 'validations', // customize the root path for validation messages.
    i18n,
    dictionary: {
      fr: validationMessages
    }
  })

  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router: router(i18n),
    store,
    render: h => h(App),
    i18n
  })
})
