<template>
  <div class="summary" :class="{'hidden-summary': hiddenSummary}">
    <div class="card center leaderboard" v-if="(isArray && Object.keys(arrays).length > 0) || !isArray">
      <div v-if="isArray">
        <table-statistics :arrays="arrays"></table-statistics>
      </div>
      <span class="mb-3" v-else>{{ summaryText }}</span>
      <b-spinner v-show="loading" variant="primary"></b-spinner>
      <div class="d-flex gap-4 align-items-center">
        <button v-if="exportFunc !== null" type="button" class="btn btn-sm btn-light csv" :disabled="loading" v-show="loading===false" @click="loadingCSV" v-b-tooltip="'Extraction de vos lignes sélectionnées dans un fichier en CSV pour éditer sous excel ou un logiciel de comptabilité'"><img class="mr-3 d-none d-lg-inline-block" width="24" src="@/assets/img/csv-icon.svg" alt="export csv"/>{{ $t('global.exports.csv') }}</button>
        <button v-if="pdfFunc !== null" type="button" class="btn btn-sm btn-light pdf" :disabled="loading" v-show="loading===false" @click="loadingPDF" v-b-tooltip="'Téléchargement de vos PDF sélectionnés'"><img class="mr-3 d-none d-lg-inline-block" width="24" src="@/assets/img/pdf-icon.svg" alt="export pdf"/>{{ $t('global.exports.pdf') }}</button>
        <b-button v-if="deleteFunc !== null" variant="danger" @click="onDelete" v-b-tooltip.hover="'Supprimer les éléments sélectionnés'">
          <i class="bi bi-trash"></i>
        </b-button>

        <b-overlay v-if="exportBtn"
          :show="exportBtnLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <button  type="button" class="btn btn-sm btn-light csv" @click="onExportCSV">
            <img class="mr-3 d-none d-lg-inline-block" width="24" src="@/assets/img/csv-icon.svg" alt="export csv"/>{{ $t('global.exports.csv') }}
          </button>
        </b-overlay>

        <slot name="btn"></slot>
    </div>
    <i class="bi bi-x close-icon" @click="hiddenSummary=!hiddenSummary"></i>
  </div>
    <div class="showSummary" v-if="Object.keys(arrays).length > 0" @click="hiddenSummary=!hiddenSummary">
      <span class="show-icon">
        <i class="bi bi-download"></i>
      </span>
    </div>
</div>
</template>

<script>
import TableStatistics from "@/components/TableStatistics"
import { sleep } from "@/utils/helpers.service"

export default {
  name: 'Summary',
  components: {
    TableStatistics
  },
  props: {
    exportBtn: {
      type: Boolean,
      default: false
    },
    exportFileName: {
      type: String,
      default: "export.csv"
    },
    exportFunc: {
      type: Function,
      default: null
    },
    pdfFunc: {
      type: Function,
      default: null
    },
    deleteFunc: {
      type: Function,
      default: null
    },
    summaryText: {
      type: String,
      default: ""
    },
    isArray: {
      type: Boolean,
      default: false
    },
    arrays: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data () {
    return {
      exportBtnLoading: false,
      hiddenSummary: false,
      loading: false
    }
  },
  methods: {
    onDelete () {
      this.deleteBox('Voulez-vous supprimer les éléments sélectionnés ?', async () => {
        await this.deleteFunc()
      })
    },
    loadingCSV () {
      this.loading = true

      this.exportFunc(true)
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    onExportCSV: async function () {
      this.exportBtnLoading = true
      try {
        const table = document.getElementById("TableSummary")
        if (!table) {
          console.error("Tableau non trouvé !")
          return
        }

        let csv = []
        const rows = table.querySelectorAll("tr")

        // Récupérer chaque ligne du tableau
        rows.forEach(row => {
          let rowData = []
          const cols = row.querySelectorAll("th, td")

          // Récupérer les cellules de chaque ligne
          cols.forEach(col => {
            rowData.push(`"${col.innerText.replace(/"/g, '""')}"`) // Échapper les guillemets
          })

          csv.push(rowData.join(";")) // Ajouter la ligne formatée au CSV
        })

        // Convertir le tableau en chaîne CSV
        const csvContent = csv.join("\n")
        const blob = new Blob([csvContent], { type: "text/csv" })
        const link = document.createElement("a")

        link.href = URL.createObjectURL(blob)
        link.download = this.exportFileName
        link.click()

        await sleep(500)
        this.exportBtnLoading = false
      } catch (e) {
        this.exportBtnLoading = false
        return this.toastError({ message: "Une erreur est survenue" })
      }
    },
    loadingPDF () {
      this.loading = true
      this.pdfFunc(true)
      .finally(() => {
        this.loading = false
      })
      setTimeout(() => {

      }, 1000)
    },
    show () {
      this.hiddenSummary = false
    },
    hide () {
      this.hiddenSummary = true
    }
  }
}
</script>

<style scoped lang="css">
@media only screen and (max-width: 768px) {
  .summary {
    bottom: 60px;
    padding: 0;
    margin: 0;
  }
  .summary .show-icon{
    top: -70px;
  }
  .summary > .center {
    width: 100vw;
  }
}
</style>
