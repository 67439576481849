export default (axios, checkHeaders) => ({
  getInvoices (data = {}) {
    return axios.get('/statistics/invoices', { params: data.filters || {} })
  },
  getPayments (data = {}) {
    return axios.get('/statistics/payments', { params: data.filters || {} })
  },
  getPurchases (data = {}) {
    return axios.get('/statistics/purchases', { params: data.filters || {} })
  },
  getQuotes (data = {}) {
    return axios.get('/statistics/quotes', { params: data.filters || {} })
  },
  getRH (data = {}) {
    return axios.get('/statistics/rh', { params: data.filters || {} })
  },
  // NEW
  getStats (data = {}) {
    return axios.post('/statistics', data.body, { params: data.filters || {} })
  }
})
