<template>
  <div class="flex">
    <b-button variant="light" class="btn-add-something" @click="onClickButton">
      <i class="bi bi-image"></i> {{ placeholder }}
    </b-button>

    <input type="file" hidden ref="inputFile" @change="onInputChange" accept="image/*"/>
  </div>
</template>

<script>
export default {
  name: 'InputImage',
  props: {
    imageVModel: {
      type: [String, Object]
    },
    placeholder: {
      type: String,
      default: "Ajouter une photo"
    }
  },
  model: {
    prop: 'imageVModel',
    event: 'update:imageVModel'
  },
  computed: {
    inputFile: {
      get () { return this.imageVModel || [] },
      set (value) { this.$emit('update:imageVModel', value) }
    }
  },
  methods: {
    onClickButton () {
      this.$refs.inputFile.click()
    },
    onInputChange (evt) {
      if (!evt.target) return
      if (!evt.target.files) return
      if (evt.target.files.length === 0) return

      let file = evt.target.files[0]

      if (file.type.split('/')[0] !== 'image') {
        return this.toastError({ message: "Le fichier transmis n'est pas une image." })
      }
    }
  }
}
</script>
