<template>
  <div class="b-overlay-wrapper" :class="{ 'b-overlay-active': show, 'b-overlay-fixed': fixed }">
    <!-- Contenu original du slot -->
    <slot></slot>

    <!-- Overlay uniquement si `show` est true -->
    <div v-if="show" class="b-overlay position-absolute d-flex align-items-center justify-content-center" :class="{ 'rounded': rounded }">
      <div class="b-overlay-backdrop bg-light"></div>
      <div class="b-overlay-content text-center position-relative">
        <slot name="overlay">
          <div class="spinner-border spinner-border-sm" :class="spinnerClass"></div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    rounded: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    spinnerClass: {
      type: String,
      default: "text-primary"
    }
  }
}
</script>

<style scoped>
/* Conteneur principal */
.b-overlay-wrapper {
  position: relative;
  display: block;
}

/* Quand l'overlay est actif */
.b-overlay-active {
  position: relative;
}

/* Overlay */
.b-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
}

/* Mode `fixed` (plein écran) */
.b-overlay-fixed .b-overlay {
  position: fixed;
}

/* Fond semi-transparent */
.b-overlay-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

/* Contenu de l'overlay */
.b-overlay-content {
  z-index: 1051;
}
</style>
