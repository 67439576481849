<template>
  <i v-if="displayFecFields"
     class="bi bi-exclamation-triangle-fill fec-icon text-orange"
     v-b-tooltip.hover="tooltip"
     :style="{ 'font-scale': fontScale }"
  ></i>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: 'FECIcon',
  props: {
    animation: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: 'Veillez à compléter ces champs si vous souhaitez un FEC complet.'
    },
    fontScale: {
      type: String,
      default: '1.5'
    }
  },
  computed: {
    ...mapGetters(['displayFecFields']),
  },
}
</script>
