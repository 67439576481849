import AppLayout from '../layouts/AppLayout'
import store from '../store'
import {resolveLoadingApp} from "@/utils/middlewares/auth.middleware"

const beforeEnterChecker = function (to, from, next) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.MANAGE_STATS) return next({ name: "Error403" })
  }

  next()
}

export default [
  {
    path: '/:company_url/pilotage/paiements',
    name: 'StatsPayments',
    component: () => import('../pages/company/stats/StatsPayments.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'statistics.sells', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/pilotage/paiements-en-attente',
    name: 'StatsPaymentsWaiting',
    component: () => import('../pages/company/stats/StatsPaymentsWaiting.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'statistics.sells', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/pilotage',
    redirect: to => {
      return { name: 'StatsPilotage', params: { company_url: to.params.company_url, id: "general" } }
    }
  },
  {
    path: '/:company_url/pilotage/:id',
    name: 'StatsPilotage',
    component: () => import('../pages/company/stats/Pilotage.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'statistics.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
]
