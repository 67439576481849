<template>
  <div>
    <table :class="['table', tableClass, { 'table-hover': hover, 'table-striped': striped }]">
      <thead :class="theadClass">
      <tr>
        <th v-for="field in fields" :key="field.key" @click="toggleSort(field)" :class="{ 'sortable': field.sortable }">
          <slot :name="`head(${field.key})`" :field="field">
            {{ field.label || field.key }}
            <span v-if="field.sortable">{{ getSortIcon(field.key) }}</span>
          </slot>
        </th>
      </tr>
      </thead>
      <tbody v-if="!busy" :class="tbodyClass">
      <tr v-for="(item, rowIndex) in sortedItems" :key="rowIndex" @click="$emit('row-clicked', item)" @mouseover="$emit('row-hovered', item)">
        <td v-for="field in fields" :key="field.key">
          <slot :name="`cell(${field.key})`" :item="item" :field="field">
            {{ item[field.key] }}
          </slot>
        </td>
      </tr>
      <tr v-if="showEmpty && !items.length">
        <td :colspan="fields.length" class="text-center">{{ emptyText }}</td>
      </tr>
      </tbody>
      <tbody v-else>
      <slot name="table-busy">
        <tr>
          <td :colspan="fields.length" class="text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </td>
        </tr>
      </slot>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    busy: Boolean,
    emptyText: {
      type: String,
      default: 'No data available'
    },
    fields: {
      type: Array,
      required: true
    },
    hover: Boolean,
    items: {
      type: Array,
      required: true
    },
    showEmpty: Boolean,
    striped: Boolean,
    tableClass: String,
    theadClass: String,
    tbodyClass: String,
  },
  data () {
    return {
      sortBy: null,
      sortDesc: false
    }
  },
  computed: {
    sortedItems () {
      if (!this.sortBy) return this.items
      return [...this.items].sort((a, b) => {
        const modifier = this.sortDesc ? -1 : 1
        if (a[this.sortBy] < b[this.sortBy]) return -1 * modifier
        if (a[this.sortBy] > b[this.sortBy]) return 1 * modifier
        return 0
      })
    }
  },
  methods: {
    toggleSort (field) {
      if (!field.sortable) return
      if (this.sortBy === field.key) {
        this.sortDesc = !this.sortDesc
      } else {
        this.sortBy = field.key
        this.sortDesc = false
      }
      this.$emit('sort-changed', { sortBy: this.sortBy, sortDesc: this.sortDesc })
    },
    getSortIcon (fieldKey) {
      if (this.sortBy !== fieldKey) return ''
      return this.sortDesc ? '▼' : '▲'
    }
  }
};
</script>

<style scoped>
.sortable {
  cursor: pointer;
  user-select: none;
}
</style>
