import {resolveLoadingApp} from "@/utils/middlewares/auth.middleware"
import AppLayout from "@/layouts/AppLayout"
import store from "@/store"

const beforeEnterCheckerSeeDeliveryReceipts = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    let seeMy = store.getters.currentRights.SEE_MY_DELIVERY_RECEIPTS
    let seeTeam = store.getters.currentRights.SEE_TEAM_DELIVERY_RECEIPTS
    let seeAll = store.getters.currentRights.SEE_ALL_DELIVERY_RECEIPTS

    if (!seeMy && !seeTeam && !seeAll) return next(redirect)
  }

  next()
}

const beforeEnterCheckerAddDeliveryReceipt = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.CREATE_DELIVERY_RECEIPT && !store.getters.currentRights.UPDATE_DELIVERY_RECEIPT) return next(redirect)
  }

  next()
}

export default [
  /** DELIVERY RECEIPT */
  {
    path: '/:company_url/delivery-receipts',
    name: 'DeliveryReceiptList',
    component: () => import('../pages/company/deliveryReceipt/DeliveryReceiptList.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeDeliveryReceipts(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'documents.quote_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/delivery-receipt/:id/update',
    name: 'DeliveryReceiptUpdate',
    component: () => import('../pages/company/deliveryReceipt/DeliveryReceiptForm'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddDeliveryReceipt(to, from, next, { name: 'DocumentHtmlWithoutCompanyUrl', params: { id: to.params.id } })

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'documents.add_quote', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/delivery-receipt/create',
    name: 'DeliveryReceiptCreate',
    component: () => import('../pages/company/deliveryReceipt/DeliveryReceiptForm'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddDeliveryReceipt(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'documents.add_quote', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/delivery-receipt/:id/html',
    name: 'DeliveryReceiptHtml',
    component: () => import('../pages/company/deliveryReceipt/DeliveryReceiptHTML'),
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isAuthenticated) {
        return next({ name: 'DeliveryReceiptHtmlWithoutCompanyUrl', params: { id: to.params.id } })
      }
      await beforeEnterCheckerSeeDeliveryReceipts(to, from, next, { name: 'DeliveryReceiptHtmlWithoutCompanyUrl', params: { id: to.params.id } })

      await resolveLoadingApp(to, from, next)
    },
    // Ici il n'y a pas de authenticate true pour permettre la redirection vers la vue client si la personne n'a pas l'accès ce document en vue HTML Company
    meta: { title: 'quote', layout: AppLayout, activeMenu: "app", company: { auth: true, redirect: (to) => ({ name: 'DeliveryReceiptHtmlWithoutCompanyUrl', params: { id: to.params.id } }) } }
  },
]
