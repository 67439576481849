<template>
  <div class="actions sub-menu">
    <div v-for="(menuItem, index) in menuItems" :key="'action_menu_' + index" v-if="checkRights(menuItem, currentPosition) && index <= 0" class="">
      <!-- if disabled it's button -->
      <b-button v-if="(menuItem.route && menuItem.disabled) || disabled"
                variant="primary"
                v-b-tooltip="{ content: menuItem.tooltip, placement:'bottom'}"
                @click="() => $router.push(menuItem.route)"
                class="w_actions_button"
                :disabled="menuItem.disabled || disabled">
        <i :class="menuItem.icon" class="icon mr-1"></i><span>{{ menuItem.label || menuItem.tooltip }}</span>
      </b-button>
      <!-- if not it's a link -->
      <router-link v-if="menuItem.route && !menuItem.disabled && !disabled" v-b-tooltip="{ content: menuItem.tooltip, placement:'bottom'}" :to="menuItem.route" class="btn btn-primary">
        <i :class="menuItem.icon" class="icon mr-1"></i><span>{{ menuItem.label || menuItem.tooltip }}</span>
      </router-link>
      <a v-if="menuItem.method" class="btn btn-primary" @click="menuItem.method">
        <i :class="menuItem.icon" class="icon mr-1"></i><span>{{ menuItem.label || menuItem.tooltip }}</span>
      </a>
    </div>

    <b-dropdown v-if="menuItems && menuItems.length > 1" class="more-items" :disabled="disabled" no-caret right variant="outline-secondary">
      <template slot="button-content">
        <i class="icon ion-ios-more"></i>
      </template>
      <b-dropdown-item v-for="(menuItem, index) in menuItems" :disabled="menuItem.disabled || disabled" :key="'action_dropdown_' + index" v-if="checkRights(menuItem, currentPosition) && index > 0" href="javascript:void(0)">
        <router-link v-if="menuItem.route" :to="menuItem.route" class="text-body">
          <i :class="menuItem.icon" class="icon"></i>{{ menuItem.label || menuItem.tooltip }}
        </router-link>
        <div v-if="menuItem.loading">
          <b-spinner small variant="primary"></b-spinner>
        </div>
        <a v-if="menuItem.method" @click="menuItem.method">
          <i :class="menuItem.icon" class="icon"></i>{{ menuItem.label || menuItem.tooltip }}
        </a>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import {checkRights} from '@/utils/rights.service'
import {mapGetters} from "vuex"

export default {
  name: 'ActionsSubmenu',
  data () {
    return {
      fabStartOpened: true
    }
  },
  computed: {
    ...mapGetters(['currentPosition'])
  },
  props: {
    menuItems: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
    }
  },
  methods: {
    checkRights
  }
}
</script>

<style scoped>
.nav {
  flex-direction: column;
  margin-top: 3em;
}

.nav > li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: bold;
  margin-bottom: 1em;
  font-size: 16px;
}
.nav > li > ul > li {
  font-size: 14px;
  font-weight: normal;
  margin: 10px 0;
  padding-left: 2em;
}

.nav > li > ul > li.active {
  font-weight: bold;
}

.accordion li {
  color: #969696;
  font-size: 15px;
}

.nav a.menu-selected {
  color: #00b2aa;
}
</style>
