<template>
  <div class="d-inline-flex">
    <div :id="id"
         :class="['custom-avatar', shapeClass, variantClass]"
         :style="avatarStyle"
    >
      <template  v-if="src">
        <img :src="src" :alt="alt" @error="onError" />
      </template>
      <template v-else-if="icon">
        <i :class="icon"></i>
      </template>
      <template v-else>
        {{ initialsText }}
      </template>
    </div>
    <b-tooltip :target="id" v-if="tooltip">
      {{ tooltip }}
    </b-tooltip>
  </div>
</template>

<script>
let uuidAvatar = 0

export default {
  name: 'CustomAvatar',
  beforeCreate () {
    // Sert à générer un id unique pour chaque composant pour que le tooltip s'applique correctement
    this.id = 'custom-avatar-' + uuidAvatar.toString()
    uuidAvatar += 1
  },
  props: {
    src: String,
    alt: String,
    text: String,
    icon: String,
    size: {
      type: [String, Number],
      default: 35
    },
    variant: {
      type: String,
      default: 'primary'
    },
    rounded: {
      type: Boolean,
      default: true
    },
    tooltip: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      error: false
    }
  },
  computed: {
    initialsText () {
      if (this.text) {
        return this.text
          .split(' ')
          .map(word => word.charAt(0).toUpperCase())
          .join('')
      }
      return ''
    },
    avatarStyle () {
      return {
        width: this.size + 'px',
        height: this.size + 'px',
        lineHeight: this.size + 'px',
        fontSize: this.size / 2 + 'px'
      }
    },
    shapeClass () {
      return this.rounded ? 'rounded-circle' : 'rounded'
    },
    variantClass () {
      return `bg-${this.variant}`
    }
  },
  methods: {
    onError () {
      this.error = true
      this.src = ''
    }
  }
}
</script>

<style scoped>
/* NE PAS BOUGER CE STYLE DE CE COMPOSANT */
.custom-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #ccc;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: 0.5px solid #ccc;
}

.custom-avatar:hover {
  transform: scale(1.3);
}

.custom-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
