export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/notifications-lists', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/notifications-list', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/notifications-list/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/notifications-list/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/notifications-list/' + data.id, { params: data.filters || {} })
  },
  getActions (data = {}) {
    return axios.get('/notifications-list-actions', { params: data.filters || {} })
  },
  postOneSub (data = {}) {
    return axios.post('/notifications-list/' + data.id + '/sub', data.body, { params: data.filters || {} })
  },
  patchOneSub (data = {}) {
    return axios.patch('/notifications-list/' + data.id + '/sub/' + data.subId, data.body, { params: data.filters || {} })
  },
  deleteOneSub (data = {}) {
    return axios.delete('/notifications-list/' + data.id + '/sub/' + data.subId, data.body, { params: data.filters || {} })
  },
  testWebhook (data) {
    return axios.post('/notifications-list-test-webhook', data.body, { params: data.filters })
  }
})
