import AppLayout from '../layouts/AppLayout'
import {resolveLoadingApp} from "@/utils/middlewares/auth.middleware"
import store from "@/store"

const beforeEnterCheckerManageBankReconciliation = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    let checkManage = store.getters.currentRights.MANAGE_BANK_RECONCILIATION

    if (!checkManage) return next(redirect)
  }

  next()
}

export default [
  {
    path: 'bank-reconciliation-start',
    name: 'BankReconciliationStart',
    component: () => import('../pages/company/bankReconciliation/BankReconciliationStart.vue'),
    beforeEnter: async (to, from, next) => {
      if (!store.getters.currentCompany.options.fec) return next({ name: 'Error403' })

      await beforeEnterCheckerManageBankReconciliation(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'home.title', layout: AppLayout, activeMenu: 'app', authenticate: true, company: true }
  },
  {
    path: 'bank-reconciliations',
    name: 'BankReconciliationList',
    component: () => import('../pages/company/bankReconciliation/BankReconciliationList.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerManageBankReconciliation(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'home.title', layout: AppLayout, activeMenu: 'app', authenticate: true, company: true }
  },
  {
    path: 'bank-reconciliation/:id',
    name: 'BankReconciliation',
    component: () => import('../pages/company/bankReconciliation/BankReconciliation.vue'),
    beforeEnter: async (to, from, next) => {
      if (!store.getters.currentCompany.options.fec) return next({ name: 'Error403' })

      await beforeEnterCheckerManageBankReconciliation(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'home.title', layout: AppLayout, activeMenu: 'app', authenticate: true, company: true }
  },
]
