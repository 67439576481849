export function loadChatbot () {
  if (document.getElementById("widget-chatbot-js")) return // Évite de charger plusieurs fois

  const script = document.createElement("script")
  script.id = "widget-chatbot-js"
  script.src = "https://www.talkia.tech/js/embed/widget.js?v=3"
  script.setAttribute("company-slug", "wuro")
  script.setAttribute("chatbot-slug", "w03-rh")
  script.setAttribute("port", "3004")
  script.setAttribute("chatbot-url", "https://www.talkia.tech")
  script.setAttribute("display", "classic")
  script.setAttribute("defer", true)
  script.setAttribute("onload-display", "close")

  document.body.appendChild(script)
}
export function removeChatbot () {
  const script = document.getElementById("widget-chatbot-js")
  if (script) script.remove()

  // Supprimer aussi les éventuels éléments créés par le script
  const chatbotWidget = document.querySelector(".talkia-chatbot-widget")
  if (chatbotWidget) chatbotWidget.remove()
}
