<template>
  <div class="people d-flex" :class="{'full': full}" @click="showTags=false">
    <b-avatar variant="light" :size="avatarSize" :src="people.avatar" :text="initials(people)" href="javascript:void(0)" @click="() => onShowPosition(people)"></b-avatar>

    <div class="details">
      <h1 @click="onNameClick" v-if="currentRights.MANAGE_RIGHTS || this.people._id === this.currentPosition._id" :class="{'employee': employee}">{{ people.first_name }} {{ people.last_name }}</h1>
      <h1 v-else>{{ people.first_name }} {{ people.last_name }}</h1>
      <h2 v-if="people.name" class="position"><i class="bi bi-briefcase"></i> {{ people.name }}</h2>
      <div class="phone">
        <h2 v-if="people.phone"><i class="bi bi-telephone"></i>
          <span v-if="typeof people.phone==='object'"><a :href="'tel:'+people.phone.number">{{ people.phone.number }}</a></span>
          <span v-else><a :href="'tel:'+people.phone">{{ format(people.phone) }}</a></span>
        </h2>
        <h2 v-if="people.mainPosition" :set="pos = people.mainPosition">
          <div v-if="pos.phone&&pos.phone.number"><i class="bi bi-telephone"></i><a :href="'tel:'+pos.phone.number">{{ format(pos.phone.number) }}</a></div>
          <div v-if="pos.personal_phone_fixe"><i class="bi bi-telephone"></i><a :href="'tel:'+pos.personal_phone_fixe">{{ format(pos.personal_phone_fixe) }}</a></div>
          <div v-if="pos.professional_phone"><i class="bi bi-telephone"></i><a :href="'tel:'+pos.professional_phone">{{ format(pos.professional_phone) }}</a></div>
          <div v-if="pos.professional_phone_fixe"><i class="bi bi-telephone"></i><a :href="'tel:'+pos.professional_phone_fixe">{{ format(pos.professional_phone_fixe) }}</a></div>
        </h2>
        <h2 v-if="customPhones.length > 0" v-for="(phone,index) in customPhones" :key="'custom-phone-' + index">
          <i class="bi bi-telephone"></i><a :href="'tel:'+ phone.content">{{ phone.content }}</a>
        </h2>
        <h2 v-if="people.linkedin">
          <i class="bi bi-linkedin"></i>
          <a :href="people.linkedin.includes('linkedin.com/in')?people.linkedin:'https://www.linkedin.com/in/'+people.linkedin">
            Profil LinkedIn
          </a>
        </h2>
      </div>
      <div class="email">
        <h2 v-if="people.email">
          <i class="bi bi-envelope"></i>
          <a :href="'mailto:'+people.email">{{ people.email }}</a>
        </h2>
        <h2 v-if="customEmails.length > 0" v-for="(email,index) in customEmails" :key="'custom-email-' + index">
          <i class="bi bi-envelope"></i>
          <a :href="'mailto:'+ email.content">{{ email.content }}</a>
        </h2>
      </div>
      <div class="tags" v-if="full && people.tags">
        <div class="backdrop" v-if="showTags" @click="showTags=false;$root.$emit('bv::hide::popover')"></div>
        <div :id="'popover-target-interl' + people._id" class="w-auto tags">
          <span v-for="tag in people.tags"
                :key="'people_' + people._id + '_badge_' + tag._id"
                :style="'background-color:'+tag.custom_color"
                class="badge" >{{ tag.name }}</span>
        </div>
      </div>
      <div class="actions" v-if="full">
        <div class="email-button" @click="onEmailClick" :class="{'disabled':!people.email||people.email===''}">
          <i class="bi bi-envelope"></i> E-mail
          <div class="details">{{ people.email }}</div>
        </div>
        <div class="call-button" @click="onCallClick" :class="{'disabled':!people.phone||people.phone===''}">
          <i class="bi bi-telephone-forward"></i> Appeler
          <div class="details">{{ format(people.phone) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'people',
  props: {
    people: {
      type: Object,
      default: () => {}
    },
    full: {
      type: Boolean,
      default: false
    },
    employee: {
      type: Boolean,
      default: false
    },
    avatarSize: {
      type: String,
      default: '100px'
    }
  },
  watch: {
    showTags (newVal, oldVal) {
      // We ve just hide the popover, we should save client
      if (newVal === false && oldVal === true) {
        this.$emit("on-tags-close", this.people)
      }
    }
  },
  data () {
    return {
      hidden: false,
      customPhones: [],
      customEmails: [],
      showTags: false
    }
  },
  created () {
    let _this = this

    let customFields
    if (this.people.mainPosition) {
      customFields = this.people.mainPosition.custom_fields
    } else {
      customFields = this.people.custom_fields
    }

    if (customFields && customFields.length) {
      customFields.forEach(f => {
        if (f.pattern === 'phone' && _this.customPhones.length < 2) {
          _this.customPhones.push(f)
        } else {
          if (f.pattern === 'email' && _this.customEmails.length < 2) {
            _this.customEmails.push(f)
          }
        }
      })
    }
  },
  methods: {
    format (number = "") {
      var nonInt = /\D/g
      var allNumbers = /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/
      var formatStyle = "$1 $2 $3 $4 $5"
      if (!number.replace) return
      return number ? number.replace(nonInt, '').replace(allNumbers, formatStyle) : ''
    },
    onEmailClick () {
      if (this.people.email && this.people.email !== '') {
        window.location.href = "mailto:" + this.people.email
      }
    },
    onCallClick () {
      if (this.people.phone && this.people.phone !== '') {
        window.location.href = "tel:" + this.people.phone
      }
    },
    onNameClick () {
      if (this.employee) {
        this.$router.push({ name: "ParamsPosition", params: { id: this.people._id, company_url: this.currentCompany.url } })
      }
    }
  }
}
</script>

<style>
.people {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 400px;
}

.people h1 {
  margin: 0;
  padding: 2px;
  font-size: 17px !important;
}
.people h1.employee:hover {
  text-decoration: underline;
  cursor: pointer;
}
.people h2 {
  font-size: 14px !important;
  color: #BBB;
  margin-bottom: 0.2rem;
}
.people h2 .icon {
  width: 17px;
  display: inline-block;
  text-align: center;
  font-size: 13px;
  margin-right: 4px;
  vertical-align: middle;
}
.people .details {
  padding: 7px;
}
.people.full {
  position: relative;
  width: 250px;
  height: 300px;
  margin-right: 15px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.people.full .people-avatar {
  width: 100%;
  float: none;
  display: block;
  max-width: 125px;
  width: 100%;
}
.people.full .details {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  min-width: 65%;
  max-width: 99%;
  width: auto;
  padding: 0;
}
.people.full h1 {
  font-weight: bold;
  font-size: 15px !important;
  margin-top: 7px;
  margin-bottom: 4px;
  color: #333;
  max-height: 39px;
  overflow: hidden;
}
.people.full .position {
  color:#888;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.people.full .phone, .people.full .email, .people.full .position > i {
  display: none;
}
.people .tags {
  margin-top: 5px;
}
.people .tags .badge {
  padding: 3px 7px;
  font-size: 13px;
  font-weight: 400;
}
.people .actions {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: white;
}
.people .actions .details {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  bottom: 50px;
  transition: color 0.3s;
  color: white;
  visibility: hidden;
  width: 100%;
  padding: 7px 4px;
  left: 0;
  white-space: nowrap;
  background: white;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
.people .actions > div:not(.disabled):hover .details {
  color: #444;
  visibility: visible;
}
.people .actions > div {
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 9px;
  color: #555;
  font-weight: bold;
}
.people .actions > div.disabled {
  color: #DDD;
  background: #FEFEFE;
}
.people .actions > div:not(.disabled):hover {
  background: #EEE;
  color: #222;
  cursor: pointer;
}

.people .actions > div:not(.disabled):hover > i {
  color: #222;
}

.people .actions > div > i {
  margin-right: 7px;
  font-size: 21px;
  color: #AAA;
}
.people .actions > div:first-child {
  border-top: 1px solid #DDD;
  border-right: 1px solid #DDD;
}
.people .actions > div:nth-child(2) {
  border-top: 1px solid #DDD;
}
@media only screen and (max-width: 768px) {
  .people.full {
    width: 100%;
    height: 300px;
    margin-right: 15px;
    margin-left: 8px;
    margin-bottom: 10px;
  }
}
</style>
